.zalo-icon {
  width: 16px;
  fill: white;
}

img {
  vertical-align: middle;
}

.text-center {
  text-align: center;
}

.ant-select-selector {
  flex-wrap: unset !important;
  overflow: auto;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  & {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}
.filter-container {
  margin-bottom: 12px;
  .filter-item {
    label {
      font-weight: 500;
      font-size: 15px;
    }

    .ant-select-selector {
      height: 32px !important;
    }

    &.btn {
      button {
        position: relative;
        top: 10px;
      }
    }
    button {
      height: 32px !important;
    }
  }
}

@media screen and (max-width: 768px) {
  .ant-layout-sider {
    position: fixed;
    z-index: 999;
  }

  .site-layout {
    margin-left: 0 !important;
  }

  .ant-layout-sider-collapsed {
    width: 0 !important;
    min-width: 0 !important;
    z-index: -1;
  }

  .ant-layout-content {
    margin: 0 !important;
  }

  .service-duration {
    text-align: right;
  }

  .filter-container {
    .filter-item {
      &.mobile-no-top button {
        top: 0;
      }
    }
  }

  .sms-wrapper {
    padding: 0 !important;
  }
}

.pac-container.pac-logo {
  z-index: 999999;
}
.address-select-input {
  .ant-form-item-explain-error {
    margin-top: 8px;
  }
}
