@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

:root {
  --radius: 0.625rem;
  --input-radius: 0.475rem;
}

//Custom theme

html,
body {
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
}

b {
  font-weight: 600 !important;
}

span.ant-upload {
  overflow: hidden;
}

.ant-layout-sider,
.ant-menu.ant-menu-dark {
  background-color: #000;
}

.ant-layout {
  background: #f7f7f8 !important;
}

header.ant-layout-header {
  padding: 0px;
  background: #fff;
  box-shadow: 0px 10px 30px 0px rgb(82 63 105 / 5%);
}

input.ant-input,
input.ant-input-password {
  font-size: 14px !important;
  // background-color: #f5f8fa;
  // border-color: #f5f8fa;
  color: #686c80;
  transition: color 0.2s ease, background-color 0.2s ease;
  // font-weight: 500;
  // border-radius: var(--input-radius);
}

.ant-table-thead > tr > th {
  background-color: #f5f8fa !important;
}

.ant-table-container table > thead > tr:first-child th:first-child {
  border-top-left-radius: var(--radius) !important;
}

.ant-table-container table > thead > tr:first-child th:last-child {
  border-top-right-radius: var(--radius) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  border-color: #abca74 !important;
  background-color: #abca74 !important;
}

.ant-tag {
  font-weight: 800;
  padding: 2px 10px !important;
  border-radius: 5px !important;
  border: none !important;

  &.ant-tag-green {
    color: #50cd89 !important;
    background-color: #e8fff3 !important;
    border-color: #e8fff3 !important;
  }

  &.ant-tag-cyan {
    color: #abca74 !important;
    background-color: #f1faff !important;
    border-color: #f1faff !important;
  }
}

// .ant-input-number {
//   overflow: hidden;
//   border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
// }

.ant-input-group-addon,
.ant-input-number-group-addon {
  border: none !important;
  border-radius: var(--input-radius) 0 0 var(--input-radius) !important;
  background-color: #11161930 !important;
}

.custom-input-number {
  .ant-input-number-group-addon {
    border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
  }
  width: 100%;
}

.ant-input-group-addon:last-child {
  // padding: 0;
  border-radius: 0 var(--input-radius) var(--input-radius) 0 !important;
}

input::placeholder {
  color: #878a98 !important;
}

.box {
  background-color: #fff;
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
  padding: 2em;
  border-radius: var(--radius) !important;
}

.ant-form-item-label label {
  font-weight: 500;
}

.ant-breadcrumb-link {
  font-weight: bold;
}

.ant-breadcrumb a {
  color: #e3e3e373 !important;
}

.ant-breadcrumb-separator {
  color: #e3e3e373 !important ;
}

.textarea.ant-input {
  border-radius: 5px;
}

.ant-breadcrumb > span {
  font-size: 18px;
  text-transform: capitalize;
  font-weight: 600;
}

.ant-breadcrumb > span:last-child {
  font-size: 18px;
  font-weight: 600;
}

//form
.ant-form-item.form-label-center {
  .ant-form-item-label {
    text-align: center;
  }
}

.ant-select-selector,
.ant-picker {
  padding: 4px 11px !important;
  font-size: 14px !important;
  color: #5e6278 !important;
  .ant-select-selection-placeholder {
    color: #686c80;
  }
}

// .ant-modal-content {
.ant-btn,
.ant-select-selector.custom,
.custom-input input,
.custome-select .ant-select-selector {
  height: 32px !important;
}

.custome-select .ant-select-selector {
  padding: 5px 10px !important;
}
.ant-select-selector.custom {
  padding-top: 5px !important;
}

//Custom theme

html,
body {
  font-family: "Inter", sans-serif !important;
  -webkit-font-smoothing: antialiased;
}

.ant-btn-link {
  border-color: #e4e7e9 !important;
}
.ant-select-selector,
.ant-picker,
.ant-btn {
  border-radius: var(--input-radius) !important;
  // font-weight: 500;
  // background-color: #f5f8fa !important;
  // border-color: #f5f8fa !important;
  color: #5e6278 !important;
}

.ant-picker {
  input {
    // font-weight: 500;
    color: #5e6278 !important;
  }
}

// End custom theme
.ant-modal {
  input.ant-input:not([disabled]),
  input.ant-input-password {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
    color: #686c80;
    transition: color 0.2s ease, background-color 0.2s ease;
    // font-weight: 500;
    // border-radius: var(--input-radius);
  }

  .ant-select-selector,
  .ant-picker {
    background-color: #fff !important;
    border: 1px solid #d9d9d9 !important;
  }

  .ant-input-password {
    background-color: #fff !important;
    border-color: #d9d9d9 !important;
  }
}

.ant-upload-list-item-card-actions-btn {
  border: none !important;
  background-color: transparent !important;
}

.ant-btn-primary {
  color: #fff !important;
  background-color: #abca74 !important;

  -webkit-transition: all 0.3s;
  font-weight: 500;
  font-size: 13px;
  padding: 0 15px;
  transition: all 0.3s;
  height: 28px !important;

  &:hover {
    background-color: #539e66 !important;
    color: white;
  }
}

.btn-login {
  &.ant-btn {
    height: 40px !important;
    font-size: 13px;
    padding: 0 15px;
  }
}
.ant-btn {
  height: 28px !important;
  font-size: 13px;
  padding: 0 15px;
}

.ant-btn-default:hover {
  background-color: #f4f4f4 !important;
}

.ant-btn-dangerous {
  color: #f1416c !important;
  border-color: #ffdfdf !important;
  background-color: #ffdfdf !important;

  &:hover {
    color: #ffffff !important;
    border-color: #f1416c !important;
    background-color: #f1416c !important;
  }
}

.ant-pagination {
  li,
  .ant-pagination-item-link {
    border: none !important;
  }

  input {
    height: 30px !important;
    text-align: center;
    border-radius: var(--input-radius) !important;
  }

  li a:hover,
  .ant-pagination-item-link:hover {
    z-index: 2;
    color: #abca74;
    text-decoration: none;
    background-color: #f1faff;
    border-color: #abca74;
    border-radius: 5px;
  }

  .ant-pagination-item-active {
    background-color: #abca74;
    color: #fff !important;
    border-radius: 5px;

    a {
      color: #fff;
    }

    a:hover {
      background-color: #abca74;
      color: #fff !important;
    }
  }

  .ant-pagination-options .ant-select .ant-select-selector {
    height: inherit !important;
  }
}

.ant-btn-primary[disabled] {
  background-color: #ccc !important;
  color: #fff !important;
  cursor: not-allowed !important;
}

// .ant-btn.ant-btn-primary.ant-btn-dangerous {
//   border-color: #ff4d4f !important;
//   background: #ff4d4f !important;
// }

.ant-menu-item,
.ant-menu-submenu-title {
  font-weight: 600;
}

.ant-btn-link {
  color: #1890ff !important;
  border-color: #dfeffd !important;
  font-weight: bold;
}

.ant-btn-link:hover {
  background-color: #dfeffd !important;
}

.ant-switch {
  background: rgba(0, 0, 0, 0.45);
}

.ant-menu {
  .ant-menu-item,
  .ant-menu-submenu-title {
    padding-left: 16px !important;
  }
}

.ant-menu-submenu-vertical {
  .-title-content {
    display: none;
  }
  .ant-menu-submenu-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
.ant-switch.ant-switch-checked,
.ant-switch.ant-switch-checked:hover {
  background: #539e66 !important;
}

.ant-table-cell {
  padding: 6px 16px !important;
}

// .new-visible-btn {
//   background-color: #f1416c !important;

//   .ant-switch-inner-unchecked {
//     color: #2ed573 !important;
//   }
//   .ant-switch-inner-checked {
//     // color:  !important;
//   }
// }

// .ant-tabs-tab.ant-tabs-tab-active {
//   color: #50cd89 !important;
// }

.row-dragging {
  height: auto !important;
  width: 100%;
  background: #fff !important;
  border: 1px solid #ccc;
  z-index: 9999;
  display: flex;
  .ant-table-cell {
    padding: 12px !important;
    display: flex;
    align-items: center;
    flex: 1;
  }
}

/* For WebKit browsers */
::-webkit-scrollbar {
  width: 6px; /* Set the width of the scrollbar */
  height: 6px;
}

/* Track (the area on which the thumb moves) */
::-webkit-scrollbar-track {
  background-color: none; /* Set the background color of the track */
}

/* Handle (the draggable part of the scrollbar) */
::-webkit-scrollbar-thumb {
  background: #ccc;
  border-radius: 4px; /* Set the border radius of the thumb */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
