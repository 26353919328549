.address-modal {
  .pac-target-input {
    border: 1px solid #efefef;
    border-radius: 5px;
    padding-left: 10px;
    margin-bottom: 20px;

    &:focus {
      outline: none;
      border-color: #666;
    }
  }

  .ant-modal-body {
    height: 400px;
  }
}

.custom-link {
  .ant-input-group-addon:last-child {
    padding: 0;
    background-color: transparent;
  }
  &.ant-form-item .ant-form-item-label > label {
    height: auto;
  }
  &.ant-form-item .ant-form-item-label {
    text-align: start;
  }

  .location-btn {
    height: 32px !important;
  }

  button {
    border-radius: 0 0.475rem 0.475rem 0 !important;
    border: none !important;
  }
}
