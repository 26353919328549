.ant-layout-sider-children {
  height: unset;
}

.ant-menu {
  padding: 8px;
  max-height: calc(100vh - 32px);
  overflow-y: auto;

  .ant-menu-item-selected {
    border-radius: 5px;
    // box-shadow: 0 0 10px 1px #1890ffb3;
  }
}
