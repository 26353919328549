.site-layout-background .anticon.trigger {
  color: #fff;
}

.ant-layout {
  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: #abca74;
    }
  }

  .logo {
    height: 32px;
    margin: 16px;
    display: flex;
    justify-content: center;
    // background: rgba(255, 255, 255, 0.3);

    img {
      height: 100%;
      // width: fit-content;
    }
  }
}

// header
.ant-layout-header {
  position: fixed;
  width: calc(100% - 250px);
  top: 0;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  transition: width 0.2s;
  &.collapsed {
    width: calc(100% - 80px);
  }
}

// content
.ant-layout-content {
  margin-top: 64px !important;
  min-height: calc(100vh - 88px);
}

.site-layout {
  transition: margin-left 0.2s;
}

// sider
.ant-layout-sider {
  position: fixed;
  height: 100vh;
}

@media screen and (max-width: 768px) {
  .ant-layout-header.collapsed {
    width: 100%;
    .ant-tag {
      display: block !important;
    }
  }

  .merchant-table {
    margin-top: 2em;
  }
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: #abca74 !important;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: #abca74 !important;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 3px; /* Set the width of the scrollbar */
  height: 6px;
}
